import React, {useState} from "react";
import _ from "lodash";
import {functions} from "../firebase/firebaseConfig";
import Layout from "../components/layout";
import Title from "../components/title";
import {useForm} from "react-hook-form";
// import Axios from "axios";
// import firebase from "firebase/app";
// const firebase = require("firebase");
// require("firebase/functions");

import "../styles/object/component/card.scss";
import "../styles/object/component/input.scss";
import "../styles/object/component/tag.scss";
import "../styles/object/component/textarea.scss";
import "../styles/object/component/typography.scss";
import "../styles/object/project/contact.scss";
import {navigate} from "gatsby";
import define from "../config/define";

const ContactForm = () => {
  const {
    register,
    formState: {errors},
    handleSubmit
  } = useForm({
    mode: "onChange"
  });

  const [agree, setAgree] = useState(false);

  const types = [
    {subject: "システム開発について", val: "1"},
    {subject: "WEB制作について", val: "2"},
    {subject: "プロダクトについて", val: "3"},
    {subject: "採用について", val: "4"},
    {subject: "その他", val: "5"}
  ];

  const onSubmit = async(data) => {
    const {company, department, email, message, name, phone, type} = data
    const sendData = {
      company, department, email, message, name, phone,
      type: _.find(types, {val: type}).subject,
    }
    try {
      const sendMail = functions.httpsCallable('sendMail');
      await sendMail(sendData)
      await navigate("/successfullysent");
    } catch (e) {
      console.error(e);
    }
  };
//チェックボックス
  const checkboxHandler = () => {
    setAgree(!agree);
  };

  return (
    <>
      <Layout title="Contact">
        <Title top="CONTACT" topSub="お問い合わせ"/>
        <main className="l-main">
          <section className="contact">
            <div className="container">
              <p className="c-textAttention">
                弊社に関するお問い合わせ内容を以下の項目にご記入ください。
                <br/>担当者より返信いたします。
              </p>
              <div className="c-card">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="contactFormContainer">
                    <label
                      htmlFor="contactEmail"
                      className="contactFormContainer_title"
                    >
                      お問い合わせ種類
                      <span className="c-tag c-tag--alert">必須</span>
                    </label>
                    <div className="contactFormContainer_form">
                      <select id="contactType" name="type" {...register("type", {
                        required: "お問い合わせ種類は必須項目です。",
                      })}>
                        <option value="">
                          お問い合せ種類を選択してください。
                        </option>
                        {types.map((c) => (
                          <option key={c.val} value={c.val}>{c.subject}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <section className="error">
                    {errors.type && <p className="error-msg">{errors.type.message}</p>}
                  </section>

                  <div className="contactFormContainer">
                    <label
                      htmlFor="contactEmail"
                      className="contactFormContainer_title"
                    >
                      メールアドレス
                      <span className="c-tag c-tag--alert">必須</span>
                    </label>
                    <div className="contactFormContainer_form">
                      <input
                        id="contactEmail"
                        className="c-inputText"
                        placeholder="例）example@plmin.us.com"
                        type="email"
                        name="email"
                        {...register("email", {
                          required: "メールアドレスは必須項目です。",
                          pattern: {
                            value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                            message: "メールアドレスは正しい形式で入力してください。"
                          }
                        })}
                      />
                    </div>
                  </div>
                  <section className="error">
                    {errors.email && <p className="error-msg">{errors.email.message}</p>}
                  </section>

                  <div className="contactFormContainer">
                    <label
                      htmlFor="contactName"
                      className="contactFormContainer_title"
                    >
                      氏名<span className="c-tag c-tag--alert">必須</span>
                    </label>
                    <div className="contactFormContainer_form">
                      <input
                        id="contactName"
                        className="c-inputText"
                        placeholder="例）山田太郎"
                        type="text"
                        name="name"
                        {...register("name", {
                          required: "氏名は必須項目です。",
                          maxLength: {
                            value: 15,
                            message: "氏名は15文字以内で入力して下さい。"
                          },
                          minLength: {
                            value: 4,
                            message: "氏名は4文字以上で入力して下さい。"
                          }
                        })}
                      />
                    </div>
                  </div>
                  <section className="error">
                    {errors.name && <p className="error-msg">{errors.name.message}</p>}
                  </section>

                  <div className="contactFormContainer">
                    <label
                      htmlFor="contactPhoneNumber"
                      className="contactFormContainer_title"
                    >
                      電話番号
                    </label>
                    <div className="contactFormContainer_form">
                      <input
                        id="contactPhoneNumber"
                        className="c-inputText"
                        placeholder="例）0123456789"
                        type="tel"
                        name="phone"
                        {...register("phone", {
                          pattern: {
                            value: /^(0{1}\d{9,10})$/,
                            message: "電話番号は正しい形式で入力してください。"
                          }
                        })}
                      />
                    </div>
                  </div>
                  <section className="error">
                    {errors.phone && <p className="error-msg">{errors.phone.message}</p>}
                  </section>

                  <div className="contactFormContainer">
                    <label
                      htmlFor="contactCompany"
                      className="contactFormContainer_title"
                    >
                      会社名
                    </label>
                    <div className="contactFormContainer_form">
                      <input
                        id="contactCompany"
                        className="c-inputText"
                        placeholder={`例）${define.corp.name}`}
                        type="text"
                        name="company"
                        {...register("company", {
                          maxLength: {
                            value: 20,
                            message: "会社名は20文字以内で入力して下さい。"
                          }
                        })}
                      />
                    </div>
                  </div>
                  <section className="error">
                    {errors.company && <p className="error-msg">{errors.company.message}</p>}
                  </section>

                  <div className="contactFormContainer">
                    <label
                      htmlFor="contactDepartment"
                      className="contactFormContainer_title"
                    >
                      部署名
                    </label>
                    <div className="contactFormContainer_form">
                      <input
                        id="contactDepartment"
                        className="c-inputText"
                        placeholder="例）開発部"
                        type="text"
                        name="department"
                        {...register("department", {
                          maxLength: {
                            value: 20,
                            message: "部署名は20文字以内で入力して下さい。"
                          }
                        })}
                      />
                    </div>
                  </div>
                  <section className="error">
                    {errors.department && <p className="error-msg">{errors.department.message}</p>}
                  </section>

                  <div className="contactFormContainer">
                    <label
                      htmlFor="contactBody"
                      className="contactFormContainer_title"
                    >
                      お問い合わせ内容
                      <span className="c-tag c-tag--alert">必須</span>
                    </label>
                    <div className="contactFormContainer_form">
                      <textarea
                        id="contactBody"
                        className="c-textarea"
                        placeholder="例）問い合わせ内容を記入してください"
                        name="message"
                        {...register("message", {
                          required: "お問い合わせ内容は必須項目です。"
                        })}
                      />
                    </div>
                  </div>
                  <section className="error">
                    {errors.message && <p className="error-msg">{errors.message.message}</p>}
                  </section>

                  <div className="contactCheckBoxContainer">
                    <div className="check-box">
                      <input
                        type="checkbox"
                        id="contactAgree"
                        onChange={checkboxHandler}
                      />
                      <label htmlFor="contactAgree">
                        <a href="/privacy" target="_blank" className="c-linkBorder">
                          <b>個人情報の取り扱い</b>
                        </a>
                        に同意します。
                      </label>
                    </div>
                  </div>
                  <div className="contactButtonContainer">
                    <button
                      disabled={!agree}
                      className={!agree ? "disabled c-button c-button--l" : "c-button c-button--l c-button--gradation"}
                      type="submit"
                    >
                      上記の内容を送信する
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </section>
        </main>
      </Layout>
    </>
  );
};

export default ContactForm;
