import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";
import "firebase/firestore";
import 'firebase/functions';

// Initialize Firebase
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
  trackingIds: process.env.REACT_APP_GA_TRACKING_ID,
};


firebase.firestore.setLogLevel("debug");
if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig)
}
const db = firebase.firestore();
const functions = firebase.app().functions('asia-northeast1');

// const testMessage = functions.httpsCallable('test_message_firebase_json');
// testMessage({message: 'Hello'}).then((result) => {
//   console.log(result.data);
// }).catch((error) => {
//   // Getting the Error details.
//   // var code = error.code;
//   // var message = error.message;
//   // var details = error.details;
//   console.log(error);
// });

// functions.useFunctionsEmulator('/functions');
// const testMessage = functions.httpsCallable('test_message_firebase_json');

// const functions_url="https://us-central1-plmin-us-2c9dd.cloudfunctions.net/submit";
// export { db ,functions_url};
export { db ,functions};
